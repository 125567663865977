import projImg1 from "../assets/img/EsportsDesign/01.png";
import projImg2 from "../assets/img/EsportsDesign/02.png";
import projImg3 from "../assets/img/EsportsDesign/03.png";
import projImg4 from "../assets/img/EsportsDesign/04.png";
import projImg5 from "../assets/img/EsportsDesign/05.png";
import projImg6 from "../assets/img/EsportsDesign/06.png";
import projImg7 from "../assets/img/EsportsDesign/07.png";
import projImg8 from "../assets/img/EsportsDesign/08.png";
import projImg9 from "../assets/img/EsportsDesign/09.png";

import logimg1 from '../assets/img/Logo-Designs/001.png'
import logimg2 from '../assets/img/Logo-Designs/002.png'
import logimg3 from '../assets/img/Logo-Designs/003.png'
import logimg4 from '../assets/img/Logo-Designs/004.jpg'
import logimg5 from '../assets/img/Logo-Designs/005.png'
import logimg6 from '../assets/img/Logo-Designs/006.png'

import banimg1 from '../assets/img/banner-designs/001.png'
import banimg2 from '../assets/img/banner-designs/002.png'
import banimg3 from '../assets/img/banner-designs/003.png'
import banimg4 from '../assets/img/banner-designs/004.png'
import banimg5 from '../assets/img/banner-designs/005.png'
import banimg6 from '../assets/img/banner-designs/006.png'

export const Designs = [
    {
        title: "Esports Image",
        description: "An image made to welcome cBumm",
        imgUrl: projImg1,
    },
    {
        title: "Esports Image",
        description: "An image made for the interview of cBumm",
        imgUrl: projImg2,
    },
    {
        title: "Wallpaper",
        description: "Personal Wallpaper made for myself",
        imgUrl: projImg3,
    },
    {
        title: "Youtube Thumbnail",
        description: "An image made for a youtube channel",
        imgUrl: projImg4,
    },
    {
        title: "Esports Image",
        description: "An image made to welcome Eve Maxi",
        imgUrl: projImg5,
    },
    {
        title: "Esports Image",
        description: "An image made for a war league",
        imgUrl: projImg6,
    },
    {
        title: "Announcement Image",
        description: "An image made for a minecraft event",
        imgUrl: projImg7,
    },
    {
        title: "Esports Image",
        description: "An image made for a competative event",
        imgUrl: projImg8,
    },
    {
        title: "Youtube Thumbnail",
        description: "An image made for a youtube channel",
        imgUrl: projImg9,
    },
];
export const Logos = [
    {
        title: "Mascot Logo",
        description: "A mascot logo drawn with my hands",
        imgUrl: logimg1,
    },
    {
        title: "uh-huh  Logo",
        description: "A logo made for my discord",
        imgUrl: logimg2,
    },
    {
        title: "TeamRevolution Logo",
        description: "A Logo for my startup",
        imgUrl: logimg3,
    },
    {
        title: "MansoorLogo",
        description: "A Logo for myself",
        imgUrl: logimg4,
    },
    {
        title: "KhrayLogo",
        description: "A Logo for myself",
        imgUrl: logimg5,
    },
    {
        title: "Stax Logo",
        description: "A Logo for Stax",
        imgUrl: logimg6,
    },
]

export const BannerDesigns = [
    {
        title: "Sphyre Banner",
        description: "A Banner for a client",
        imgUrl: banimg1,
    },
    {
        title: "GamerMike Banner",
        description: "A Banner for a client",
        imgUrl: banimg2,
    },
    {
        title: "Team Revolution",
        description: "A Banner for a my startup",
        imgUrl: banimg3,
    },
    {
        title: "AG Esports",
        description: "A Banner for a AG esports",
        imgUrl: banimg4,
    },
    {
        title: "KhrayBanner",
        description: "A Banner for myself",
        imgUrl: banimg5,
    },
    {
        title: "Stax Banner",
        description: "A Banner for Stax",
        imgUrl: banimg6,
    },

]