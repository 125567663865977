import {Col,Row, Container} from "react-bootstrap";
import {useState, useEffect} from "react";
import {ArrowRightCircle} from 'react-bootstrap-icons'
import headerImg from '../assets/img/Header/header-img.svg'
import "animate.css"
import TrackVisibility from "react-on-screen";
export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0)
    const toRotate = ["Graphic Designer", "logo Designer", "Photo Editor"]
    const [isDeleting, setIsDeleting] = useState(false)
    const [text, setText] = useState('')
    const [delta,setDelta] = useState(300 - Math.random() * 100)
    const period = 2500;
    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1)
        setText(updatedText)

        if(isDeleting) {
            setDelta(prevDelta => prevDelta / 2)
        }
        if(!isDeleting && updatedText === fullText) {
            setIsDeleting(true)
            setDelta(period)
        } else if(isDeleting && updatedText === '') {
            setIsDeleting(false)
            setLoopNum(loopNum + 1)
            setDelta(2000)
        }
    }
    useEffect(() => {
        let ticker = setInterval(() => {
            tick()
        }, delta)
        return () => {clearInterval(ticker)}
    }, [text]);
    return(
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <TrackVisibility>
                            {({isVisible}) =>
                            <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                        <span className="tagline">Welcome to my Portfolio</span>
                        <h1>{`Hi I'm Mansoor, `}<span className="wrap">{text}</span></h1>
                                <p>I'm also known as "Khray", my age is 18. Graphic Designing and Logo making has been my hobby ever since my childhood and i enjoy every little bit of it. My skills are not just limit to making designs as I can help with making a brand Identity and so much more. With over 4 years of experience, I will be your best choice for your needs, Waiting to see you on the other side</p>
                        <button onClick={() => console.log('connect')}>Let's Connect <ArrowRightCircle size={25}/></button>
                            </div>}
                        </TrackVisibility>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <img src={headerImg} alt="Header Img"/>
                    </Col>
                </Row>
            </Container>
        </section>
)
}