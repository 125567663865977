import {Col, Container, Row} from "react-bootstrap";
import logo from '../assets/img/logo.svg'
import navIcon1 from '../assets/img/NavBar/01.png'
import navIcon2 from '../assets/img/NavBar/002.png'
import navIcon3 from '../assets/img/nav-icon3.svg'

export const Footer = () => {
    return(
        <footer className="footer">
            <Container>
                <Row className='align-items-center'>
                    <Col sm={6}>
                        <img src={logo} alt="Logo"/>
                    </Col>

                    <Col sm={6} className="text-center text-sm-end">
                        <div className="social-icon">
                            <a href="https://github.com/mansoorkhateeb"><img src={navIcon1} alt=""/> </a>
                            <a href="https://discord.gg/Gn8nZJEQpD"><img src={navIcon2} alt=""/> </a>
                            <a href="https://www.instagram.com/_mansoor.khateeb_/"><img src={navIcon3} alt=""/> </a>
                        </div>
                        <p>Copyright 2024. All Rights Reserved - Khray</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}